var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wallet-balance" },
    [
      _vm.cryptoData
        ? _vm._l(_vm.cryptoData, function (asset, index) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.tooltip(asset.info),
                    expression: "tooltip(asset.info)",
                  },
                ],
                key: index,
                staticClass: "asset-wrapper",
              },
              [
                _c("img", {
                  staticClass: "icon",
                  attrs: { src: asset.image, alt: `${asset} icon` },
                }),
                _c("p", { staticClass: "name" }, [_vm._v(_vm._s(asset.name))]),
                _c("p", { staticClass: "price" }, [
                  _vm._v(
                    _vm._s(_vm._f("formatPrice")(asset.price, _vm.currency))
                  ),
                ]),
                _c(
                  "p",
                  {
                    class: `percent ${asset.percentChange > 0 ? "up" : "down"}`,
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("formatPercentage")(asset.percentChange)
                        ) +
                        " "
                    ),
                  ]
                ),
              ]
            )
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }